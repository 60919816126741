import React from 'react'
import { collectibles_light, sell_light, upload_light, wallet_light } from 'shared/icons'
import CreateAndSellCard from './components/CreateAndSellCard'
import {
  CreateAndSellCardSubText,
  CreateAndSellCardText,
  CreateAndSellCardTextLink,
  CreateAndSellSectionHeader,
  CreateAndSellSectionLayout,
} from './CreateAndSellSection.style'

const CreateAndSellSection = () => {
  return (
    <>
      <CreateAndSellSectionHeader>Create and sell your NFTs</CreateAndSellSectionHeader>
      <CreateAndSellSectionLayout>
        <CreateAndSellCard
          header="1. Add your wallet"
          buttonText="Set-up Wallet"
          cardIcon={wallet_light}
        >
          <CreateAndSellCardText>
            Have one already? Skip to the good bit (step 2).
          </CreateAndSellCardText>
          <CreateAndSellCardSubText>
            No wallet? No problem. It takes five minutes to set one up with
            <CreateAndSellCardTextLink> our wallet setup guide.</CreateAndSellCardTextLink>
          </CreateAndSellCardSubText>
        </CreateAndSellCard>
        <CreateAndSellCard
          header="2. Create a collection"
          buttonText="Create Collection"
          cardIcon={collectibles_light}
        >
          <CreateAndSellCardText>
            <CreateAndSellCardText>
              Jungle NFTs need to sit within a collection.
            </CreateAndSellCardText>
            <CreateAndSellCardSubText>
              Once your wallet is connected you can set up the collection and add your NFTs to it.
            </CreateAndSellCardSubText>
          </CreateAndSellCardText>
        </CreateAndSellCard>{' '}
        <CreateAndSellCard
          header="3. Add your NFTs"
          buttonText="Create NFTs"
          cardIcon={upload_light}
        >
          <CreateAndSellCardText>
            <CreateAndSellCardText>Now you can create an NFT!</CreateAndSellCardText>
            <CreateAndSellCardSubText>
              Exciting, right? Just upload your image/video/audio, write a cool description and even
              add unlockable content.
            </CreateAndSellCardSubText>
          </CreateAndSellCardText>
        </CreateAndSellCard>{' '}
        <CreateAndSellCard
          header="4. Sell your NFTs"
          buttonText="Sell your NFTs"
          cardIcon={sell_light}
        >
          <CreateAndSellCardText>
            <CreateAndSellCardText>
              List your NFT for auction or a fixed price, and continue to earn creator royalties
              every time your NFT is resold.
            </CreateAndSellCardText>
            <CreateAndSellCardSubText>
              Got Questions?<CreateAndSellCardTextLink> Try our FAQs.</CreateAndSellCardTextLink>
            </CreateAndSellCardSubText>
          </CreateAndSellCardText>
        </CreateAndSellCard>
      </CreateAndSellSectionLayout>
    </>
  )
}

export default CreateAndSellSection

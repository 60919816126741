import { useAppSelector } from 'hooks/useAppSelector'
import { useGetTrendingCollectionQuery } from 'logic/reactQuery/collectionsService'
import { useGetCategoriesQuery } from 'logic/reactQuery/homePageService'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import NextImage from 'shared/components/nextImage/NextImage'
import TabFilter from 'shared/components/tabFilter/TabFilter'
import { Container, LoadingCard, PrimaryButton, SectionHeader, ThreeColumns } from 'styles/defaultStyles'
import {
  CollectionDiv,
  FilterDiv,
  LoaderDiv,
  TrendingContainer,
  TrendingHeader,
  TrendingLayout,
  Wrapper,
  Marquee,
  MarqueeGroup,
  MarqueeGroup2,
  UserInfo,
  InfoText,
  CollectionName,
  UserName,
  NoDataDiv,
  MarketPlaceBtn,
} from './TrendingNew.style'

const TrendingNew = () => {

  const { theme } = useAppSelector(state => state.theme)
  const [item, setItem] = useState('all')
  const [slide1Data, setSlide1Data] = useState<any>([])
  const [slide2Data, setSlide2Data] = useState<any>([])
  const [scroll, setScroll] = useState<boolean>(true)

  const { data: categories, isLoading: categoryLoading } = useGetCategoriesQuery()
  const { isLoading, data }: any = useGetTrendingCollectionQuery(item)

  const categoryOptions =
    !categoryLoading && categories
      ? categories?.map((category: any) => ({
        value: category?.categorySlug,
        label: category?.categoryName,
      }))
      : []

  const firstOptions = {
    value: 'all',
    label: 'All',
  }
  categoryOptions.unshift(firstOptions)

  useEffect(() => {
    // setSlide1Data([])
    // setSlide2Data([])
    if (!isLoading) {
      const middleIndex = Math.ceil((data?.message?.length) / 2);
      const firstHalf = data?.message?.splice(0, middleIndex);
      const secondHalf = data?.message?.splice(-middleIndex);
      setSlide1Data([...firstHalf])
      setSlide2Data([...secondHalf])
    }
  }, [isLoading, item])

  const router = useRouter()

  return (
    <TrendingLayout>
      <Container>
        <TrendingHeader>
          <ThreeColumns>
            <div></div>
            <SectionHeader>Trending</SectionHeader>
            <FilterDiv>
              <TabFilter
                data={categoryOptions}
                item={item}
                setItem={setItem}
              />
            </FilterDiv>
          </ThreeColumns>
        </TrendingHeader>
      </Container>
      <TrendingContainer>
        <Wrapper>
          {
            slide1Data?.length > 0 ?
              <Marquee>
                {Array.from({ length: 2 }, (x, v) => (
                  <MarqueeGroup scroll={scroll}>
                    <Carousel 
                      data={slide1Data}
                      setScroll={setScroll}
                      router={router}
                    />
                  </MarqueeGroup>
                ))}
              </Marquee>
              : null
          }
          {
            slide2Data?.length > 0 ?
              <Marquee>
                {Array.from({ length: 2 }, (x, v) => (
                  <MarqueeGroup2 scroll={scroll}>
                    <Carousel 
                      data={slide2Data}
                      setScroll={setScroll}
                      router={router}
                    />
                  </MarqueeGroup2>
                ))}
              </Marquee>
              : null
          }
        </Wrapper>
        {Array.from({ length: 2 }, (x, v) => (
          isLoading &&
          <LoaderDiv>
            {Array.from({ length: 3 }, (x, v) => (
              <LoadingCard themeMode={theme} width={406} height={311} />
            ))}
          </LoaderDiv>
        ))}
        {
          (slide1Data.length === 0 && slide2Data.length === 0) ?
            <NoDataDiv>No Data Currently</NoDataDiv> : null
        }
      </TrendingContainer>
      <MarketPlaceBtn>
        <PrimaryButton
          onClick={() => router.push(`/assets`)}
        >Visit Marketplace</PrimaryButton>
      </MarketPlaceBtn>
    </TrendingLayout>
  )
}

const Carousel = (props: any) => {

  const { data, setScroll, router } = props

  return (
    data?.map((col: any, i: number) => (
      <CollectionDiv
        key={new Date().getTime() + i + col?.id}
        onMouseOver={() => setScroll(false)}
        onMouseOut={() => setScroll(true)}
        onClick={() => {
          router.push(`/collections/${col?.slug}`)
        }}
      >
        <NextImage
          src={col?.logo}
          alt=""
          width="406px"
          height="311px"
          style={{ borderRadius: '23px' }}
        />
        <UserInfo>
          <NextImage
            alt=''
            src={col?.owner?.imageUrl}
            width={55}
            height={55}
            style={{ borderRadius: '100%' }}
          />
          <InfoText>
            <CollectionName>{col?.name}</CollectionName>
            <UserName>{col?.owner?.userName}</UserName>
          </InfoText>
        </UserInfo>
      </CollectionDiv>
    ))
  )
}

export default TrendingNew
import React, { useState } from 'react'
import { SelectTimeContainer, SelectTimeLayout, SelectTimeValue } from './SelectTime.style'

interface I_SelectTimeData {
  id: number
  label: string
  value: string
}
interface I_SelectTime {
  selectTime: I_SelectTimeData[]
  isTimeSelected: string
  setTimeSelected(value: string): void
}
const SelectTime = ({ selectTime, isTimeSelected, setTimeSelected }: I_SelectTime) => {
  return (
    <SelectTimeLayout>
      <SelectTimeContainer>
        {selectTime.map(val => (
          <SelectTimeValue
            select={val?.value === isTimeSelected}
            onClick={() => setTimeSelected(val.value)}
            key={val?.id}
          >
            <div>{val?.label}</div>
          </SelectTimeValue>
        ))}
      </SelectTimeContainer>
    </SelectTimeLayout>
  )
}

export default SelectTime

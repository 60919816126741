import { useState, useEffect } from 'react'
import { leftArrowCarousel } from "shared/icons";
import styled from "styled-components";
import NextImage from "../nextImage/NextImage";

const SliderPrevArrowNew = (props: any) => {

  const { onClick } = props;
  const [disable, setDisable] = useState(false)

  useEffect(() => {
    if (onClick === null) {
      setDisable(true)
    } else {
      setDisable(false)
    }
  }, [onClick])

  return (
    <LeftArrow>
      <IconContainer isDisable={disable}>
        <NextImage
          onClick={onClick}
          alt=''
          src={leftArrowCarousel}
        />
      </IconContainer>
    </LeftArrow>
  );

}

export default SliderPrevArrowNew

const LeftArrow = styled.div`
  position: relative;
  z-index: 1000;
`
const IconContainer = styled.div<any>`
  border-radius: 100%;
  background-color: ${({ theme }) => theme?.phaseTwoColor?.brandColor};
  width: 70px;
  height: 70px;
  display: flex;
  justify-content: center;
  position: absolute;
  cursor: pointer;
  top: 200px;
  cursor: ${({ isDisable }) => (isDisable ? 'not-allowed' : 'pointer')};
  opacity: ${({ isDisable }) => (isDisable ? 0.8 : 1)};
`
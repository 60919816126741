import { useAppSelector } from "hooks/useAppSelector";
import Link from "next/link";
import { FlexBox, PrimaryButton, SecondaryButton } from "../../styles/defaultStyles";
import {
  BannerButtons,
  BannerDescription,
  BannerLayout,
  BannerLayoutLeft,
  BannerLayoutRight,
  BannerText,
  TopCard,
  TopCardWrapper,
} from "./Banner.style";
import { MeetJungleVideo } from "./MeetJungle.style";
import TopSectionCard from "./TopSectionCard";
import { useEffect, useState } from "react";
import { useFeatureCollectionsData } from "logic/reactQuery/homePageService";

export const Banner = () => {
  const { theme } = useAppSelector((state) => state.theme);
  const { isLoggedIn } = useAppSelector(state => state.user)
  const [featureData, setFeatureData] = useState<any>({})
  //@ts-ignore
  const{ loading, data } = useFeatureCollectionsData();
  useEffect(() => {
    setFeatureData(data?.message?.collection)
  }, [loading, data])

  return (
    <>
      <BannerLayout>
        <BannerLayoutLeft>
          <BannerText>
            Jungle. <br />
            The <span className="color"> Safest </span> Marketplace for <span>Authentic</span> NFTs.
          </BannerText>
          <BannerDescription>
            It’s a jungle out there - that’s why we became the safest digital marketplace to create
            and trade NFTs.
          </BannerDescription>
          <BannerButtons>
            <Link href="/explore-collections">
              <PrimaryButton margin="0 10px 0 0">Explore</PrimaryButton>
            </Link>
            <Link href="/create">
              <SecondaryButton>Create</SecondaryButton>
            </Link>
          </BannerButtons>
        </BannerLayoutLeft>
        <BannerLayoutRight themeMode={theme}>
            { !loading && featureData &&
            <Link href={`/collections/${featureData[0]?.name}`} passHref>
              <div className="one">
            <TopSectionCard
              avatar={featureData[0]?.logo}
              nftImage={featureData[0]?.featureImage ||  featureData[0]?.logo}
              headerText={featureData[0]?.name || ''}
              user={featureData[0]?.owner.userName || ''}
            /> 
          </div>
          </Link>}
            {!loading && featureData &&
            <Link href={`/collections/${featureData[1]?.name}`} passHref>
            <div className="two">
            <TopSectionCard
              avatar={featureData[1]?.logo}
              nftImage={featureData[1]?.featureImage || featureData[1]?.logo}
              headerText={featureData[1]?.name || ''}
              user={featureData[1]?.owner.userName || ''}
            />
            </div>
            </Link>
            }
          <div className="three">
          {/* <MeetJungleVideo
          poster="https://jungle-development-bucket.s3.amazonaws.com/profile/1661514562018-pexels-david-ria%C3%B1o-cort%C3%A9s-975771.jpg"
          controls
        >
          <source
            src="https://jungle-development-bucket.s3.amazonaws.com/profile/1661514527324-videoplayback%20%281%29.mp4"
            type="video/mp4"
          />
        </MeetJungleVideo> */}

          </div>
        </BannerLayoutRight>
      </BannerLayout>
      <TopCardWrapper>
        <TopCard active>
          <h1>More Rewards,Fewer Scams</h1>
        </TopCard>
        <TopCard>
          <h1>Get Rewards</h1>
          <p>Get cash back for every NFT you purchase. </p>
          <p>Receive royalty fees anytime your NFT is resold.</p>
        </TopCard>
        <TopCard active>
          <h1>A Safer Community</h1>
          <p>
            Jungle protects buyers by verifying the authenticity of digital tokens on the Jungle NFT
            Marketplace
          </p>
        </TopCard>
      </TopCardWrapper>
      <FlexBox style={{ marginTop: '48px' }}>
        <Link href={!isLoggedIn ? '/login' : '/explore-collections'} passHref>
        <PrimaryButton>Get Started</PrimaryButton>
        </Link>
      </FlexBox>
    </>
  )
};

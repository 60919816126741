import styled from 'styled-components'
import { customColors } from 'styles/theme'
import { media } from 'utilities/helpers'
export const SelectTimeLayout = styled.div``
export const SelectTimeContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  align-content: center;
  width: 295px;
  height: 52px;
  grid-gap: 3px;
  background-color: ${({ theme }) => theme.phaseTwoColor.categoryColor};
  border-radius: 70px;
  padding: 0px 3px;
  ${media.xl`
  height: 45px;
  width: 292px;
  `}
`
export const SelectTimeValue = styled.div<any>`
  height: 46px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 70px;
  cursor: pointer;
  font-size: 16px;
  font-weight: ${props => (props.select ? 700 : 400)};
  background-color: ${props => (props.select ? customColors.white : 'none')};
  div {
    padding: 0px 2px;
  }
  ${media.xl`
  height: 40px;
  `}
`

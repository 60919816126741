import { useAppSelector } from 'hooks/useAppSelector'
import { useGetCategoriesQuery } from 'logic/reactQuery/homePageService'
import { useRouter } from 'next/router'
import NextImage from 'shared/components/nextImage/NextImage'
import { LoadingCard, PrimaryButton } from 'styles/defaultStyles'
import {
  CategoriesContainer,
  CategoriesHeadingText,
  CategoriesImageWrapper,
  CategoriesLayout,
  CategoriesName,
  ViewAllButtonWrapper,
} from './Categories.style'

const Categories = () => {
  const { data, isLoading }: any = useGetCategoriesQuery()
  const router = useRouter()
  const { theme } = useAppSelector(state => state.theme)
  return (
    <>
      <CategoriesHeadingText>Categories</CategoriesHeadingText>
      <CategoriesLayout>
        {data?.slice(0, 9)?.map((info: any) => (
          <CategoriesContainer
            key={info?.id}
            onClick={() => router.push(`/collection/${info.categorySlug}`)}
          >
            <CategoriesImageWrapper>
              <NextImage
                src={info?.categoryImageUrl}
                alt={info?.categoryName}
                layout="fill"
                objectFit="cover"
                style={{ borderRadius: '100%' }}
              />
            </CategoriesImageWrapper>
            <CategoriesName>{info?.categoryName}</CategoriesName>
          </CategoriesContainer>
        ))}
        {isLoading &&
          Array.from({ length: 9 }, () => (
            <LoadingCard
              themeMode={theme}
              height={100}
              width={300}
              style={{ marginBottom: '15px', borderRadius: '100px' }}
            />
          ))}
      </CategoriesLayout>
      <ViewAllButtonWrapper>
        <PrimaryButton>View All</PrimaryButton>
      </ViewAllButtonWrapper>
    </>
  )
}

export default Categories

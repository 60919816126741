import { useAppSelector } from 'hooks/useAppSelector'
import React from 'react'
import {
  LoadingNotableCardContainer,
  LoadingNotableCardLayout,
  LoadingNotableCollectionName,
  LoadingNotableHorizontalLine,
  LoadingNotableLiveBox,
} from './LoadingNotableCard.style'

const LoadingNotableCard = () => {
  const { theme } = useAppSelector(state => state.theme)

  return (
    <LoadingNotableCardLayout>
      <LoadingNotableCardContainer themeMode={theme}>
        <LoadingNotableHorizontalLine themeMode={theme}>
          <LoadingNotableLiveBox themeMode={theme}></LoadingNotableLiveBox>
          <LoadingNotableCollectionName themeMode={theme}></LoadingNotableCollectionName>
        </LoadingNotableHorizontalLine>
      </LoadingNotableCardContainer>
    </LoadingNotableCardLayout>
  )
}

export default LoadingNotableCard

import { useAppSelector } from 'hooks/useAppSelector'
import React from 'react'
import NextImage from 'shared/components/nextImage/NextImage'
import {
  TopSectionWrapper,
  ImageWrapper,
  AvatarImage,
  HeaderText,
  BottomText,
} from './TopSectionCard.style'

interface CardProps {
  nftImage?: any
  avatar?: any
  headerText?: string
  user?: string
}
const TopSectionCard = (props: CardProps) => {
  const { theme } = useAppSelector(state => state.theme)
  const { nftImage, avatar, headerText, user } = props
  return (
    <TopSectionWrapper themeMode={theme}>
      <ImageWrapper>
        <NextImage
          src={nftImage}
          objectFit="cover"
          layout="fill"
          style={{ borderRadius: '15px' }}
        />
      </ImageWrapper>
      <AvatarImage>
        <NextImage src={avatar} style={{ borderRadius: '100%' }} objectFit="cover" layout="fill" />
      </AvatarImage>
      <HeaderText>{headerText}</HeaderText>
      <BottomText>by {user}</BottomText>
    </TopSectionWrapper>
  )
}

export default TopSectionCard

import styled from 'styled-components'
import { animateHover } from 'styles/animations'
import { customColors } from 'styles/theme'
export const CreateAndSellCardImageLayout = styled.div<any>`
  width: 74px;
  height: 74px;
  background: ${({ themeMode }) =>
    themeMode === 'light' ? customColors.white : customColors.black};
  border-radius: 50%;
  display: grid;
  place-items: center;
  position: absolute;
  top: -37px;
  right: 18px;
  box-shadow: inset 0px 2px 8px rgba(0, 0, 0, 0.25);
  padding: 10px;
`
export const CreateAndSellCardImageContainer = styled.div<any>`
  height: 100%;
  width: 100%;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.phaseTwoColor.brandColor};
`
export const CreateAndSellCardLayout = styled.div`
  height: 348px;
  position: relative;
  background-color: ${customColors.white};
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  padding: 25px;
  margin: 10px 0px;
  &:hover {
    animation: ${animateHover} ease-in-out infinite 2s;
  }
`
export const CreateAndSellCardContainer = styled.div``
export const CreateAndSellCardHeader = styled.div`
  width: 178px;
  font-size: 23px;
  font-weight: 700;
  line-height: 30px;
  margin-bottom: 25px;
`
export const CreateAndSellButtonWrapper = styled.div`
  margin-top: 25px;
`

export const CreateAndSellButton = styled.button`
  padding: 10px 29px;
  outline: none;
  border: none;
  background-color: ${({ theme }) => theme.phaseTwoColor.brandColor};
  color: ${customColors.white};
  font-size: 16px;
  font-weight: 400;
  border-radius: 50px;
  cursor: pointer;
`

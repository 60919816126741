import React from 'react'
import { FlexBox } from 'styles/defaultStyles'
import {
  LoadingTopCollectionFloorValue,
  LoadingTopCollectionImage,
  LoadingTopCollectionLayout,
  LoadingTopCollectionName,
} from './LoadingTopCollection.style'

const LoadingTopCollection = () => {
  return (
    <LoadingTopCollectionLayout>
      <LoadingTopCollectionImage></LoadingTopCollectionImage>

      <div>
        <LoadingTopCollectionName></LoadingTopCollectionName>
        <LoadingTopCollectionName></LoadingTopCollectionName>
      </div>

      <div>
        <LoadingTopCollectionFloorValue></LoadingTopCollectionFloorValue>
        <LoadingTopCollectionFloorValue></LoadingTopCollectionFloorValue>
      </div>
    </LoadingTopCollectionLayout>
  )
}

export default LoadingTopCollection

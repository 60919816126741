import styled from 'styled-components'
import { customColors } from 'styles/theme'
import { media } from 'utilities/helpers'
export const CategoriesHeadingText = styled.div`
  font-family: 'Stray900';
  font-size: 50px;
  color: ${({ theme }) => theme.phaseTwoColor.text};
  text-align: center;
  margin: 50px 0px;
  ${media.sm`
  margin: 30px 0px;
  font-size: 30px;
  `}
`

export const CategoriesLayout = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 20px;
`
export const CategoriesContainer = styled.div<any>`
  color: ${customColors.black};
  background-color: ${({ theme }) => theme.phaseTwoColor.categoryColor};
  border-radius: 100px;
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 10px 0px;
  padding: 10px 12px;
  cursor: pointer;
  transition: transform 450ms;
  cursor: pointer;
  :hover {
    transform: scale(1.03);
  }
`
export const CategoriesImageWrapper = styled.div`
  position: relative;
  height: 72px;
  width: 72px;
  ${media.sm`
  height: 40px;
  width: 40px;
  `}
`

export const CategoriesName = styled.div`
  color: ${customColors.black};
  font-weight: 700;
  font-size: 23px;
  ${media.sm`
  font-size: 14px;
  font-weight: 500;
  `}
`
export const ViewAllButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 60px 0px;
  ${media.sm`
  margin: 30px 0px;
  `}
`

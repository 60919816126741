import React from 'react'
import { Container } from 'styles/defaultStyles'
import { Banner } from './Banner'
import Categories from './components/categories/Categories'
import CreateAndSell from './components/createAndSell/CreateAndSellSection'
import TopCollection from './components/topCollection/TopCollection'
import TrendingNew from './components/trending/TrendingNew'
import { MeetJungle } from './MeetJungle'
import { NotableDrops } from './NotableDrops'
import { Trending } from './Trending'
import NotableDropsNew from './components/notableDrops/NotableDropsNew'

const HomePage = () => {
  return (
    <>
      <Container>
        <Banner />
        <NotableDropsNew />
        <TopCollection />
      </Container>
      <TrendingNew />
      <Container>
        <CreateAndSell />
        <Categories />
      </Container>
    </>
  )
}

export default HomePage

import { useGetTopCollectionQuery } from 'logic/reactQuery/collectionsService'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import LoadingTopCollection from 'shared/components/loader/LoadingTopCollection'
import NextImage from 'shared/components/nextImage/NextImage'
import SelectTime from 'shared/components/selectTime/SelectTime'
import TippyToolTip from 'shared/components/tippyTooltip/TippyToolTip'
import { activeCollection_light, ethereum_icon } from 'shared/icons'
import { FlexBox, PrimaryButton } from 'styles/defaultStyles'
import { handlePrecision } from 'utilities/helpers'
import {
  ActiveCollectionIcon,
  BlockChainIconWrapper,
  TopCollectionContainer,
  TopCollectionDays,
  TopCollectionDaysWrapper,
  TopCollectionFilterMobileScreen,
  TopCollectionFilterWrapper,
  TopCollectionFloorPrice,
  TopCollectionFloorPriceLabel,
  TopCollectionFloorPriceWrapper,
  TopCollectionHeader,
  TopCollectionHeaderWrapper,
  TopCollectionImageWrapper,
  TopCollectionLayout,
  TopCollectionPercentage,
  TopCollectionSerialNumber,
  ViewRankingButtonWrapper,
} from './TopCollection.style'
const selectTime = [
  {
    id: 1,
    label: '24h',
    value: 'last24hours',
  },
  {
    id: 2,
    label: '7d',
    value: 'last7days',
  },
  {
    id: 3,
    label: '30d',
    value: 'last30days',
  },
  {
    id: 4,
    label: 'All Time',
    value: '',
  },
]
const TopCollection = () => {
  const [isTimeSelected, setTimeSelected] = useState<string>('last24hours')
  const [selectValue, setSelectValue] = useState('')
  const [selectVolume, setSelectVolume] = useState('')
  const { data, isLoading }: any = useGetTopCollectionQuery(isTimeSelected)
  const router = useRouter()
  useEffect(() => {
    if (isTimeSelected === 'last24hours') {
      setSelectValue('one_day_change')
      setSelectVolume('one_day_volume')
    } else if (isTimeSelected === 'last7days') {
      setSelectValue('seven_day_change')
      setSelectVolume('seven_day_volume')
    } else if (isTimeSelected === 'last30days') {
      setSelectValue('thirty_day_change')
      setSelectVolume('thirty_day_volume')
    }
  }, [isTimeSelected])
  return (
    <>
      <TopCollectionHeaderWrapper>
        <TopCollectionHeader>Top Collection</TopCollectionHeader>
        <TopCollectionFilterMobileScreen>
          <SelectTime
            isTimeSelected={isTimeSelected}
            setTimeSelected={setTimeSelected}
            selectTime={selectTime}
          />
        </TopCollectionFilterMobileScreen>
        <TopCollectionFilterWrapper>
          <SelectTime
            isTimeSelected={isTimeSelected}
            setTimeSelected={setTimeSelected}
            selectTime={selectTime}
          />
        </TopCollectionFilterWrapper>
      </TopCollectionHeaderWrapper>
      <TopCollectionLayout>
        {Array.isArray(data?.message) &&
          data?.message?.length > 0 &&
          data?.message?.slice(0, 9)?.map((card: any, index: number) => (
            <TopCollectionContainer
              key={card?.id}
              onClick={() => {
                router.push(`/collections/${card?.slug}`)
              }}
            >
              <TopCollectionSerialNumber>{index + 1}</TopCollectionSerialNumber>
              <TopCollectionImageWrapper>
                <NextImage
                  src={card?.logo}
                  objectFit="cover"
                  layout="fill"
                  style={{ borderRadius: '100%' }}
                />
                <ActiveCollectionIcon>
                  <NextImage src={activeCollection_light} />
                </ActiveCollectionIcon>
              </TopCollectionImageWrapper>
              <div>
                <TopCollectionDaysWrapper>
                  <TopCollectionDays>
                    {card?.name?.length > 18 ? (
                      <TippyToolTip placement="top" toolTipContent={<div>{card?.name || ''}</div>}>
                        <span>{handlePrecision(card?.name, 18)}</span>
                      </TippyToolTip>
                    ) : (
                      <>{card?.name || ''}</>
                    )}
                  </TopCollectionDays>
                  <TopCollectionPercentage>
                    + {parseFloat(card?.stats?.[selectValue]).toFixed(3)}%
                  </TopCollectionPercentage>
                </TopCollectionDaysWrapper>
                <TopCollectionFloorPriceWrapper>
                  <FlexBox>
                    <TopCollectionFloorPriceLabel>Floor price:</TopCollectionFloorPriceLabel>
                    <FlexBox>
                      <BlockChainIconWrapper>
                        <NextImage src={card?.imageUrl} height={18} width={18} />
                      </BlockChainIconWrapper>
                      <TopCollectionFloorPrice>
                        {card?.stats?.floor_price
                          ? parseFloat(card?.stats?.floor_price).toFixed(3)
                          : '0'}
                      </TopCollectionFloorPrice>
                    </FlexBox>
                  </FlexBox>
                  <FlexBox>
                    <BlockChainIconWrapper>
                      <NextImage src={card?.imageUrl} height={18} width={18} />
                    </BlockChainIconWrapper>
                    <TopCollectionDays>
                      {parseFloat(card?.stats?.[selectVolume]).toFixed(3)}
                    </TopCollectionDays>
                  </FlexBox>
                </TopCollectionFloorPriceWrapper>
              </div>
            </TopCollectionContainer>
          ))}
        {isLoading && Array.from({ length: 9 }, (x, v) => <LoadingTopCollection />)}
      </TopCollectionLayout>
      <ViewRankingButtonWrapper>
        <PrimaryButton
          onClick={() => {
            router.push(`/rankings`)
          }}
        >
          View Ranking
        </PrimaryButton>
      </ViewRankingButtonWrapper>
    </>
  )
}

export default TopCollection

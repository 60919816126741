// ! ALL THE API ROUTES STARTING WITH /api/v1/collections

import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from 'react-query'
import { CacheKeys } from './cacheKeys'
import { axiosInstance } from 'utilities/axiosInterceptor'
import {
  ICollection,
  ICreateCollectionOptions,
  IGetCollectionsQueryParams,
  IUniqueNameOrUrlQueryParams,
  ISingleCollection,
  I_Rankings,
} from 'shared/interface'
import { apiEndPoints } from 'utilities/apiUrls'
import { generateQueryString } from 'utilities/helpers'
import { useAuth } from 'hooks/useAuth'

const createCollection = (options: ICreateCollectionOptions) => {
  // TODO: Add response's type
  return axiosInstance.post<{ message: any; success: boolean; status: number }>(
    apiEndPoints.collections.create,
    options,
  )
}

const getAllCollections = (options?: IGetCollectionsQueryParams) => {
  return axiosInstance.get<{ message: ISingleCollection[] }>(
    `${apiEndPoints.collections.root}?${generateQueryString(options)}`,
  )
}

const checkCollectionNameOrUrl = (options?: IUniqueNameOrUrlQueryParams) => {
  return axiosInstance.put<{ message: boolean }>(
    `${apiEndPoints.collections.uniqueNameOrUrl}?${generateQueryString(options)}`,
  )
}

export const useCreateCollectionMutation = () => {
  // TODO: Update the local react query cache instread of invalidating the whole caches
  const queryClient = useQueryClient()
  const { user } = useAuth()
  const userId = user?.id || ''
  return useMutation(createCollection, {
    onSuccess: () => {
      queryClient.invalidateQueries([CacheKeys.collections, userId])
    },
  })
}

interface IEnabledOption {
  enabled?: boolean
}

export const useGetAllCollectionsQuery = (
  options?: IGetCollectionsQueryParams,
  otherOptions?: IEnabledOption,
) => {
  const enabled = otherOptions?.enabled
  return useQuery([CacheKeys.collections, options], () => getAllCollections(options), {
    enabled,
  })
}

export const useCheckCollectionNameOrUrlMutation = () => {
  return useMutation(checkCollectionNameOrUrl)
}

// get the user collections
const getTheUserCollectionFn = (userWalletAddress?: string | null) => {
  const url = `${apiEndPoints.collections.getUserCollections}/${userWalletAddress}`
  return axiosInstance.get<{ message?: ICollection[] }>(url)
}

export const useGetTheUserCollectionQuery = (userWalletAddress?: string | null) => {
  return useQuery(
    [CacheKeys.collections, userWalletAddress],
    () => getTheUserCollectionFn(userWalletAddress),
    {
      refetchOnWindowFocus: false,
      enabled: !!userWalletAddress,
    },
  )
}

const getCollectionById = (collectionId?: string | null) => {
  return axiosInstance.get(`${apiEndPoints.collections.root}/${collectionId}`)
}

export const useGetCollectionByIdQuery = (collectionId?: string | null) => {
  return useQuery([CacheKeys.collections, collectionId], () => getCollectionById(collectionId), {
    enabled: !!collectionId,
  })
}

interface IUpdateCollectionOptions extends ICreateCollectionOptions {
  collectionId: string
}

const updateCollection = (options: IUpdateCollectionOptions) => {
  const { collectionId, blockchain, ...rest } = options

  return axiosInstance.patch<{ message: any }>(
    `${apiEndPoints.collections.updateCollection}/${collectionId}`,
    rest,
  )
}

export const useUpdateCollectionMutation = () => {
  // TODO: Update the local react query cache instread of invalidating the whole caches
  const queryClient = useQueryClient()
  const { user } = useAuth()
  const userId = user?.id || ''
  return useMutation(updateCollection, {
    onSuccess: () => {
      queryClient.invalidateQueries([CacheKeys.collections, userId])
    },
  })
}

interface IUpdateCollaboratorsOptions {
  collectionId: string
  updateType: 'Add' | 'Remove'
  walletAddress: string
}

const updateCollaborators = (options: IUpdateCollaboratorsOptions) => {
  return axiosInstance.put(apiEndPoints.collections.updateCollaborators, options)
}

export const useUpdateCollaboratorsMutation = () => {
  return useMutation(updateCollaborators)
}

const deleteCollection = (collectionId: string) => {
  return axiosInstance.delete<{ success: boolean; message: any }>(
    `${apiEndPoints.collections.root}/${collectionId}`,
  )
}

export const useDeleteCollectionMutation = () => {
  // TODO: Update the local react query cache instread of invalidating the whole caches
  const queryClient = useQueryClient()
  const { user } = useAuth()
  const userId = user?.id || ''
  return useMutation(deleteCollection, {
    onSuccess: () => {
      queryClient.invalidateQueries([CacheKeys.collections, userId])
    },
  })
}

interface ICollectionWatchlistOptions {
  collectionId: string
  slug: string
  isWatched: boolean
}

const updateCollectionToWatchlist = (options: ICollectionWatchlistOptions) => {
  return axiosInstance.put<{ success: boolean }>(
    apiEndPoints.collections.addOrRemoveToWatchList,
    options,
  )
}

export const useUpdateCollectionWatchListMutation = () => {
  const queryClient = useQueryClient()

  return useMutation(updateCollectionToWatchlist, {
    onSuccess: (_, { slug }) => {
      const prevData: any = queryClient.getQueryData([CacheKeys.collections, slug])
      if (prevData) {
        queryClient.setQueryData([CacheKeys.collections, slug], {
          ...prevData,
          message: {
            ...prevData.message,
            isWatched: !prevData.message.isWatched,
          },
        })
      }
      queryClient.invalidateQueries([CacheKeys.getWatchCollections])
    },
  })
}

const getCollectionsByCategory = (category?: string | null) => {
  return axiosInstance.get<{ message: ISingleCollection[] }>(
    `${apiEndPoints.collections.getCollectionsByCategory}/${category}`,
  )
}

export const useGetCollectionsByCategoryQuery = (category?: string | null) => {
  return useQuery([CacheKeys.collections, category], () => getCollectionsByCategory(category), {
    enabled: !!category,
  })
}

// getRankings
const getRankings = (filters: I_Rankings) => {
  const queryParams = generateQueryString(filters)
  return axiosInstance.get(`${apiEndPoints.collections.fetchRankings}?${queryParams}`)
}

export const useGetRankings = (filters: I_Rankings) => {
  return useQuery([CacheKeys?.fetchRankings, filters], () => getRankings(filters), {
    refetchOnWindowFocus: false,
  })
}
const getTrendingCollections = (category?: string | null) => {
  const url = `${apiEndPoints.collections.getTrendingCollections}/${category}`
  return axiosInstance.get<{ message: ISingleCollection[] }>(url)
}

export const useGetTrendingCollectionQuery = (category?: string | null) => {
  return useQuery([CacheKeys.topTrending, category], () => getTrendingCollections(category), {
    enabled: !!category,
  })
}

const getTopCollections = (byTime?: string | null) => {
  const url = `${apiEndPoints.collections.getTopCollections}?time=${byTime}`
  return axiosInstance.get<{ message: ISingleCollection[] }>(url)
}

export const useGetTopCollectionQuery = (byTime?: string | null) => {
  return useQuery([CacheKeys.topCollection, byTime], () => getTopCollections(byTime))
}

// Get Price History of Collection data
interface I_PriceHistoryOptions {
  collectionIdOrSlug: string
  date: string
}

const getPriceHistoryCollectionFn = (options: I_PriceHistoryOptions) => {
  const url = `${apiEndPoints.collections.getPriceHistoryCollections}/${options.collectionIdOrSlug}?collectionIdOrSlug=${options.collectionIdOrSlug}&date=${options.date}`
  return axiosInstance.get(url)
}

export const useGetPriceHistoryCollectionQuery = (options: I_PriceHistoryOptions) => {
  return useQuery(
    [CacheKeys.priceHistoryCollection, options],
    () => getPriceHistoryCollectionFn(options),
    {
      enabled: !!options?.collectionIdOrSlug,
    },
  )
}

// Get Imported by smart contract collection details
interface I_ImportedCollectionOptions {
  chainId: string
  contractAddress: string
}

const getImportedCollectionFn = (options: I_ImportedCollectionOptions) => {
  const url = `${apiEndPoints.collections.getImportedSmartContractCollections}/address?chainId=${options.chainId}&contractAddress=${options.contractAddress}`
  return axiosInstance.get(url)
}

export const useImportedCollectionMutation = () => {
  return useMutation(getImportedCollectionFn)
}

const getCollectionListFromCollectedItem = (walletAddress?: string | null) => {
  const url = `${apiEndPoints.collections.getCollectionList}/${walletAddress}`
  return axiosInstance.get(url)
}

export const useGetCollectionListfromCollectedItemQuery = (walletAddress?: string | null) => {
  return useQuery([CacheKeys.collectionList, walletAddress], () =>
    getCollectionListFromCollectedItem(walletAddress),
  )
}

//creator Earning Fee

interface I_CreatorEarningFeeOptions {
  slug: string | undefined
  take?: number
  skip?: number
}

const getCreatorEarningsFn = ({ pageParam, queryKey }: any) => {
  const filters = queryKey[1]
  filters.skip = pageParam
  filters.take = 16
  const queryParams = generateQueryString(filters)
  const url = `${apiEndPoints.collections.getCreatorEarnings}?${queryParams}`
  return axiosInstance.get(url)
}

// get all user nfts to explore
export const useGetCreatorEarningsQuery = (filters: I_CreatorEarningFeeOptions) => {
  return useInfiniteQuery([CacheKeys?.getCreatorEarnings, filters], getCreatorEarningsFn, {
    keepPreviousData: true,
    enabled: !!filters?.slug,
    getNextPageParam: (_lastPage, pages) => {
      const pageCount = pages
        .map((val: any) => val?.[0]?.length)
        .reduce((previousValue, currentValue) => previousValue + currentValue, 0)
      if (pageCount < pages[0]?.[1]) {
        return pages?.length + 1
      } else {
        return false
      }
    },
  })
}

// Get Creator earning stats
const getCreatorEarningStats = (slug?: string | undefined) => {
  const url = `${apiEndPoints.collections.getCreatorEarningsStats}/${slug}`
  return axiosInstance.get(url)
}

export const useGetCreatorEarningsStats = (slug?: string | undefined) => {
  return useQuery([CacheKeys.creatorEarningStats, slug], () => getCreatorEarningStats(slug), {
    enabled: !!slug,
  })
}

// Claim Creator Earning
interface I_ClaimCreatorEarningProps {
  collectionSlugOrId: string
  tokenId: string
}
const claimCreatorEarningFn = (options: I_ClaimCreatorEarningProps) => {
  const url = `${apiEndPoints.collections.claimCreatorEarning}/${options.collectionSlugOrId}/${options.tokenId}`
  return axiosInstance.get(url)
}

export const useClaimCreatorEarning = (options: I_ClaimCreatorEarningProps) => {
  const queryClient = useQueryClient()
  return useMutation(claimCreatorEarningFn, {
    onSuccess: () => {
      queryClient.invalidateQueries([CacheKeys?.creatorEarningStats])
      queryClient.invalidateQueries([CacheKeys?.getCreatorEarnings])
    },
  })
}

//categories details

const getCategoriesDetails = (slug: any) => {
  const url = `${apiEndPoints.collections.categoriesDetails}/${slug}`
  return axiosInstance.get(url)
}

export const useGetCategoriesDetails = (slug?: any) => {
  return useQuery([CacheKeys.categoriesDetails, slug], () => getCategoriesDetails(slug), {
    enabled: !!slug,
  })
}

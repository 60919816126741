import styled from "styled-components";
import { customColors } from "styles/theme";

export const Container = styled.div`
    background-color: ${customColors.yellow};
    /* padding: 14px 0; */
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 50px;
    flex-wrap: wrap;

    div{
        padding: 14px 0;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 16px;
        img{
            width: 20px;
            height: 20px;
        }
        p{
            font-weight: 600;
            font-size: 14px;
            line-height: 21px;
            color:black;
        }
    }
`;

export const CloseDiv = styled.div`
    cursor: pointer;
    padding-right: 16px;
`;

export const ContainerWrapper = styled.div`
    display: grid;
    justify-content: center;
    align-items: center;
    grid-template-columns: 1fr 40px;
    background-color: ${customColors.yellow};
`;
import styled, { keyframes } from 'styled-components'
import { themeDark, themeLight } from 'styles/theme'
import { media } from 'utilities/helpers'
const SkeletonLoadingAnimation = keyframes`
  0% {
    background: #2b3641;
  }
  100% {
    background: ${themeDark.textTertiary};
  }
`
const SkeletonLoadingReverseAnimation = keyframes`
  0% {
    background: ${themeDark.textTertiary};
    
  }
  100% {
    background: #2b3641;
  }
`
export const LoadingNotableCardLayout = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const LoadingNotableCardContainer = styled.div<any>`
  border: 2px solid ${themeDark.brandColor};
  border-radius: 22px;
  height: 360px;
  width: 300px;
  position: relative;
  background-color: ${({ themeMode }) =>
    themeMode === 'light' ? themeLight.trendingCardBg : themeDark.trendingCardBg};
  box-shadow: ${({ themeMode }) =>
    themeMode === 'light' ? '0px 0px 7px rgba(101, 99, 99, 0.25)' : 'none'};
  animation: ${SkeletonLoadingAnimation} 1s linear infinite alternate !important;
  ${media.sm`
width: 100%;
`}
`
export const LoadingNotableLiveBox = styled.div<any>`
  height: 30px;
  width: 56px;
  border-radius: 5px;
  position: absolute;
  top: 20px;
  left: 20px;
  border: ${({ themeMode }) =>
    themeMode === 'light'
      ? `1px solid ${themeLight.border}`
      : `1px solid ${themeDark.trendingCardBg}`};
  animation: ${SkeletonLoadingReverseAnimation} 1s linear infinite alternate !important;
`
export const LoadingNotableCollectionName = styled.div<any>`
  height: 25px;
  width: 60%;
  border-radius: 5px;
  position: absolute;
  bottom: 45px;
  left: 20px;
  border: ${({ themeMode }) =>
    themeMode === 'light'
      ? `1px solid ${themeLight.border}`
      : `1px solid ${themeDark.trendingCardBg}`};
  animation: ${SkeletonLoadingReverseAnimation} 1s linear infinite alternate !important;
`
export const LoadingNotableHorizontalLine = styled.div<any>`
  height: 270px;
  border-bottom: ${({ themeMode }) =>
    themeMode === 'light'
      ? `1px solid ${themeLight.border}`
      : `1px solid ${themeDark.trendingCardBg}`};
`

import styled  from 'styled-components'

export const Tabs = styled.div`
  display: flex;
  gap: 10px;
  word-break: normal;
`
export const Tab = styled.div<{ active: boolean}>`
  background: ${({ theme, active }) => active ? theme?.phaseTwoColor?.brandColor : 
    theme?.phaseTwoColor?.categoryColor};
  color: ${({ theme, active }) =>  active ? theme.phaseTwoColor.bodyColor : 
    theme.phaseTwoColor.text};
  padding: 10px 29px;
  border-radius: 50px;
  font-size: 16px;
  height: 100%;
  white-space: nowrap;
  cursor: pointer;
`


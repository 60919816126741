import styled from 'styled-components'
import { SkeletonLoadingAnimation } from 'styles/sharedStyles'
import { themeDark, themeLight } from 'styles/theme'
export const LoadingTopCollectionLayout = styled.div<any>`
  border: 2px solid ${themeDark.brandColor};
  display: grid;
  grid-template-columns: 60px 1fr 0.5fr;
  grid-gap: 10px;
  border-radius: 22px;
  padding: 20px;
  overflow: hidden !important;
  position: relative;
  background-color: ${({ themeMode }) =>
    themeMode === 'light' ? themeLight.trendingCardBg : themeDark.trendingCardBg};
  box-shadow: ${({ themeMode }) =>
    themeMode === 'light' ? '0px 0px 7px rgba(101, 99, 99, 0.25)' : 'none'};
  margin-bottom: 30px;
`
export const LoadingTopCollectionImage = styled.div<any>`
  height: 60px;
  width: 60px;
  background-color: aqua;
  border-radius: 8px;
  box-shadow: ${({ themeMode }) =>
    themeMode === 'light' ? '0px 0px 7px rgba(101, 99, 99, 0.25)' : 'none'};
  animation: ${SkeletonLoadingAnimation} 1s linear infinite alternate !important;
`
export const LoadingTopCollectionName = styled.div<any>`
  height: 25px;
  border-radius: 22px;
  margin: 3px 0;
  box-shadow: ${({ themeMode }) =>
    themeMode === 'light' ? '0px 0px 7px rgba(101, 99, 99, 0.25)' : 'none'};
  animation: ${SkeletonLoadingAnimation} 1s linear infinite alternate !important;
`
export const LoadingTopCollectionFloorValue = styled.div<any>`
  height: 25px;
  border-radius: 22px;
  margin: 3px 0;
  box-shadow: ${({ themeMode }) =>
    themeMode === 'light' ? '0px 0px 7px rgba(101, 99, 99, 0.25)' : 'none'};
  animation: ${SkeletonLoadingAnimation} 1s linear infinite alternate !important;
`

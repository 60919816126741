import { useAppSelector } from "hooks/useAppSelector";
import React, { useState } from "react";
import NextImage from "shared/components/nextImage/NextImage";
import { CloseDiv, Container, ContainerWrapper } from "./NotificationBar.style";
import { useStatusData } from "logic/reactQuery/homePageService";
import closeIcon from '../../../public/assets/svg/newSvgs/close.svg'
interface I_DataProps {
  isActive: boolean;
  imageUrl: any;
  content: any;
  status: any;
}
const NotificationBar = () => {
  const { theme } = useAppSelector((state) => state.theme);
  const { data } = useStatusData();
  const [isActive, setIsActive] = useState(true)

  return (
    <ContainerWrapper>
      <Container>
        {isActive && data &&
          data?.map(
            (data: I_DataProps) =>
              data.isActive &&
              data.status === "general" && (
                <div>
                  <NextImage
                    src={theme === "light" ? data.imageUrl : data.imageUrl}
                    height={24}
                    width={24}
                    style={{ height: "16px", width: "16px" }}
                  />
                  <p>{data.content}</p>
                </div>
              )
          )}
      </Container>
      <CloseDiv onClick={() => setIsActive(false)}>
        { isActive && data && (
          <NextImage
            src={theme === "light" ? closeIcon : closeIcon}
            height={24}
            width={24}
            style={{ height: "16px", width: "16px" }}
          />
        )}
      </CloseDiv>
    </ContainerWrapper>
  );
};

export default NotificationBar;



import {
  CollectionName,
  NotableCardContainer,
  NotableContainer,
  NotableImage,
  NotableLayout,
  SliderWrapper,
  TextDiv,
  TextInfo,
  TextValues,
  UserName,
  Values,
  FloorHeading,
  NotableLive,
  ViewAllBtn
} from './NotableDropsNew.style'

import Slider from 'react-slick'
import { useGetNotableDropsQuery } from 'logic/reactQuery/homePageService'
import { useRouter } from 'next/router'
import { useAppSelector } from 'hooks/useAppSelector'
import LoadingNotableCard from 'shared/components/loader/LoadingNotableCard'
import { notableDropsSlider } from 'shared/components/sliderArrows/sliderConfig'
import { FlexBox, Heading, PrimaryButton, SectionHeader } from 'styles/defaultStyles'
import NextImage from 'shared/components/nextImage/NextImage'

const NotableCardDesign = (props: any) => {

  const { card } = props

  return (
    <NotableCardContainer>
      <NotableLive>Live</NotableLive>
      <NotableImage>
        <NextImage
          alt=''
          src={card?.logo}
          width={356}
          height={398}
          style={{ borderRadius: '20px' }}
        />
      </NotableImage>
      <TextDiv>
        <TextInfo>
          <CollectionName>{card?.name}</CollectionName>
          <UserName>{card?.owner?.userName}</UserName>
        </TextInfo>
        <TextValues>
          <FloorHeading>{'Floor'}</FloorHeading>
          <Values>{card?.stats?.floor_price}{' '}{'ETH'}</Values>
        </TextValues>
      </TextDiv>
    </NotableCardContainer>
  )
}

const NotableDropsNew = () => {

  const { data, isLoading }: any = useGetNotableDropsQuery()
  const { theme } = useAppSelector(state => state.theme)
  const router = useRouter()

  return (
    <>
      {data && Array.isArray(data?.message) && data?.message[0]?.length === 0 ? (
        <></>
      ) : (
        <NotableLayout>
          <NotableContainer>
            <FlexBox align="center" justify="center">
              <SectionHeader>Standout Drops</SectionHeader>
            </FlexBox>
            <SliderWrapper>
              <Slider {...notableDropsSlider}>
                {data &&
                  data?.message &&
                  data?.message[0]?.slice(0, 20)?.map((card: any) => (
                    <NotableCardDesign
                      card={card}
                    />
                  ))}
                {isLoading && Array.from({ length: 4 }, (x, v) => <LoadingNotableCard />)}
              </Slider>
            </SliderWrapper>
            <ViewAllBtn>
              <PrimaryButton
                onClick={() => router.push(`/explore-collections`)}
              >View All</PrimaryButton>
            </ViewAllBtn>
          </NotableContainer>
        </NotableLayout>
      )}
    </>
  )
}

export default NotableDropsNew
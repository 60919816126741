import { useAppSelector } from 'hooks/useAppSelector'
import NextImage from 'shared/components/nextImage/NextImage'
import { profile_setting } from 'shared/icons'
import {
  CreateAndSellButton,
  CreateAndSellButtonWrapper,
  CreateAndSellCardContainer,
  CreateAndSellCardHeader,
  CreateAndSellCardImageContainer,
  CreateAndSellCardImageLayout,
  CreateAndSellCardLayout,
} from './CreateAndSellCard.style'
interface ICreateAndSellProps {
  header: string
  children: React.ReactNode
  buttonText: string
  cardIcon: any
}
const CreateAndSellCard = ({ header, children, buttonText, cardIcon }: ICreateAndSellProps) => {
  const { theme } = useAppSelector(state => state.theme)
  return (
    <div>
      <CreateAndSellCardLayout>
        <CreateAndSellCardImageLayout themeMode={theme}>
          <CreateAndSellCardImageContainer>
            <NextImage src={cardIcon} />
          </CreateAndSellCardImageContainer>
        </CreateAndSellCardImageLayout>
        <CreateAndSellCardContainer>
          <CreateAndSellCardHeader>{header}</CreateAndSellCardHeader>
          <div>{children}</div>
          <CreateAndSellButtonWrapper>
            <CreateAndSellButton>{buttonText}</CreateAndSellButton>
          </CreateAndSellButtonWrapper>
        </CreateAndSellCardContainer>
      </CreateAndSellCardLayout>
    </div>
  )
}

export default CreateAndSellCard

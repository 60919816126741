import styled from 'styled-components'
import { customColors, themeDark } from 'styles/theme'
import { media } from '../../utilities/helpers'

const colors = {
  bgColor: '#E2E2E2',
  bgDark: '#343434',
}

export const BannerLayout = styled.div<any>`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin: 80px 0;
  grid-gap: 10px;
  height: auto;
  min-height: 60vh;
  ${media.lg`
  margin: 0;
   grid-template-columns : 1fr;
   grid-gap : 45px;
      `}
`
export const BannerLayoutLeft = styled.div<any>`
  padding: 0 30px;
  margin-top: 48px;
  ${media.lg`
      display : grid;
      place-items : center;
      text-align : center;
      padding:0;
     `};
`

export const BannerLayoutRight = styled.div<any>`
  margin-top: 24px;
  display: grid;
  gap: 14px;
  max-height: 550px;
  @media(max-width: 990px){
    margin-top: 64px;
  }
  @media(max-width: 800px){
    margin-top: 64px;
    grid-template-columns: 1fr 1fr;
  }

  @media(min-width: 800px){
    .one{
      grid-column: 1 / 3 ;
      grid-row: 1 / 3;
    }
    .two{
      margin-top: 16px;
      grid-column: 3 / 5;
      grid-row: 2 / 4;
    }
    .three{
      margin-top: 6px;
      height: 160px;
      background-color: grey;
      border-radius: 15px;
      
      grid-column: 1 / 3;
      grid-row: 3 / 4;
    }
  }
  
`
export const BannerText = styled.h1<any>`
  font-family: 'Stray900';
  font-size: 64px;
  line-height: 77px;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.sectionTitleColor};
  span {
    border: 1px solid #3AB712;
    border-radius: 132px;
    line-height: 64px !important;
    padding: 8px 4px 0 4px;
  }

  .color{
      border-radius: 132px;
      line-height: 64px !important;
      padding: 10px 8px 0 8px;
      background-color: #3AB712;
  }
  ${media.lg`
     font-size : 40px;
     `}

  ${media.sm`
      font-size : 34px;
  `}
`
export const BannerDescription = styled.p<any>`
  margin: 30px 0;
  font-weight: 400;
  font-size: 20px;
`
export const BannerButtons = styled.div<any>`
  max-width: 450px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${media.md`
   width:100%;
   justify-content:space-around;
     & > * {
       margin : 10px 0;
     }
     `}
`

export const TopCardWrapper = styled.div`
  padding-left: 30px;
  width: 100%;
  display: grid;
  gap: 12px;
  grid-template-columns: minmax(220px, 0.7fr) minmax(280px, 1fr) minmax(280px, 1fr);
  overflow: auto;
`;

export const TopCard = styled.div<any>`
  background-color: ${({ active }) => (active ? themeDark.brandColor : '#ECECEC')};
  border-radius: 15px;
  padding: 36px;
  transition: all 0.5s ease-in-out;
  h1 {
    font-family: 'Stray900';
    font-style: normal;
    font-weight: 900;
    font-size: 50px;
    line-height: 55px;
    text-transform: capitalize;
    color: ${({ active }) => (active ? customColors.white : customColors.black)};
  }

  p {
    margin-top: 12px;
    max-width: 330px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 23px;
    line-height: 30px;
    color: ${({ active }) => (active ? customColors.white : customColors.black)};
  }
/* 
  :hover{
    background-color: ${({ active }) => (!active ? themeDark.brandColor : '#ECECEC')};
    transform: scale(1.03);
    h1 {
    color: ${({ active }) => (!active ? customColors.white : customColors.black)};
  }

  p {
    color: ${({ active }) => (!active ? customColors.white : customColors.black)};
  }
  } */
`
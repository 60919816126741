import styled from 'styled-components'
import { customColors } from 'styles/theme'
import { media, screenSizes } from 'utilities/helpers'
export const TopCollectionHeaderWrapper = styled.div`
  position: relative;
`
export const TopCollectionFilterWrapper = styled.div`
  position: absolute;
  top: 8px;
  right: 0px;
  ${media.xl`
  display: none;
  `}
  @media (min-width: ${screenSizes.xl}px) {
    display: flex;
  }
`
export const TopCollectionFilterMobileScreen = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  @media (min-width: ${screenSizes.xl}px) {
    display: none;
  }
`

export const TopCollectionHeader = styled.div`
  font-family: 'Stray900';
  font-size: 50px;
  text-align: center;
  margin: 50px 0px;
  ${media.sm`
  margin: 30px 0px;
  font-size: 30px;
  `}
`
export const TopCollectionLayout = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  grid-gap: 50px;
  ${media.sm`
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  `}
`
export const TopCollectionContainer = styled.div`
  display: grid;
  grid-template-columns: 20px 74px 1fr;
  align-items: center;
  grid-gap: 10px;
  border-bottom: 1px solid ${customColors.black};
  padding: 20px 0px;
  cursor: pointer;
  ${media.sm`
   grid-template-columns: 20px 35px 1fr;
   grid-gap: 5px;
  `}
`
export const TopCollectionSerialNumber = styled.div`
  font-size: 16px;
  font-weight: 700;
  color: ${({ theme }) => theme.phaseTwoColor.text};
`
export const TopCollectionImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  height: 74px;
  width: 100%;
  border-radius: 100px;
  position: relative;
  ${media.sm`
  height: 35px;
  `}
`
export const TopCollectionDaysWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 5px 0px;
  ${media.sm`
  margin: 0px 0px;
  `}
`
export const TopCollectionDays = styled.div`
  font-size: 16px;
  font-weight: 700;
  color: ${({ theme }) => theme.phaseTwoColor.text};
`
export const TopCollectionPercentage = styled.div`
  font-size: 16px;
  font-weight: 400;
  color: ${({ theme }) => theme.phaseTwoColor.brandColor};
`

export const TopCollectionFloorPriceWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`
export const TopCollectionFloorPriceLabel = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: ${({ theme }) => theme.phaseTwoColor.paragraphText};
`
export const TopCollectionFloorPrice = styled.div`
  font-size: 16px;
  font-weight: 400;
  color: ${({ theme }) => theme.phaseTwoColor.text};
`

export const ActiveCollectionIcon = styled.div`
  position: absolute;
  top: 0px;
  right: 1px;
  ${media.sm`
  right: -3px;
  top: -6px;
  `}
`
export const BlockChainIconWrapper = styled.div`
  display: flex;
  margin: 0px 5px;
`
export const ViewRankingButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 60px 0px;
  ${media.sm`
  margin: 30px 0px;
  `}
`

import styled, { css }  from 'styled-components'
import { themeDark, themeLight } from 'styles/theme'
import { media } from 'utilities/helpers'

export const NotableContainer = styled.div<any>``
export const NotableLayout = styled.div<any>`
  overflow: hidden;
`
export const SliderWrapper = styled.div<any>`
  margin: 50px 0 10px 0;
  text-align: center;
`
export const NotableCard = styled.div<any>`
  position: relative;
  border: 2px solid ${themeDark.brandColor};
  box-sizing: border-box;
  border-radius: 20px;
  height: 360px;
  width: 300px !important;
  margin: auto !important;
  overflow: hidden !important;
  transition: all linear 0.3s;
  &::after {
    width: 100%;
    height: 100%;
    content: '';
    background: ${({ backgroundImage }) => `url(${backgroundImage})`};
    background-size: cover;
    position: absolute;
    inset: 0;
    z-index: -1;
    transition: all linear 0.3s;
  }
  cursor: pointer;
  transition: all linear 0.2;
  :hover {
    &:after {
      transform: scale(1.2);
    }
  }
  ${media.sm`
 width : 100% !important;
 `}
`

export const NotableCardHeader = styled.p<any>`
  /* border: 1px solid ${themeDark.white}; */
  border: 1px solid
    ${({ themeMode }) => (themeMode === 'light' ? themeLight.text : themeDark.white)};
  box-sizing: border-box;
  border-radius: 6px;
  width: fit-content;
  padding: 3px 10px;
  font-weight: 700 !important;
  text-transform: uppercase;
  font-size: 15px;
  margin: 15px;
  position: relative;
  color: ${({ themeMode }) => (themeMode === 'light' ? themeLight.text : themeDark.white)};
  &::before {
    position: absolute;
    content: '';
    width: 10px;
    height: 10px;
    background: linear-gradient(180deg, #34da4f 0%, ${themeDark.brandColor} 100%);
    box-shadow: 0px 3px 2px ${themeLight.text};
    border-radius: 50%;
    top: -4px;
    right: -5px;
  }
`

export const NotableCardFooter = styled.div<any>`
  position: absolute;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
  border-radius: 0px 0px 18px 18px;
  padding: 15px;
  text-align: initial !important;
  width: 100%;
`
export const NotableCardFooterHeading = styled.h4<any>`
  font-weight: 600 !important;
  font-size: 16px;
  color: ${themeDark.white};
  line-clamp: 1;
  height: 24px;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden !important;
`
export const NotableCardFooterDescription = styled.p<any>`
  font-weight: 400 !important;
  font-size: 12px;
  color: ${themeDark.white};
  line-clamp: 2;
  height: 36px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden !important;
`
export const NotableCardContainer = styled.div`
  background: ${({ theme }) => theme?.phaseTwoColor?.cardBg};
  border-radius: 20px;
  width: 398px;
  height: 501px;
  padding: 21px;
  position: relative;
`
export const NotableLive = styled.div`
  position: absolute;
  background: ${({ theme }) => theme?.phaseTwoColor?.liveBg};
  box-shadow: 0px 0px 12.9747px 2.16244px rgba(0, 0, 0, 0.25);
  border-radius: 50px;
  left: 40px;
  z-index: 100;
  top: 45px;
  padding: 4px 16px;
  color: ${({ theme }) => theme?.phaseTwoColor?.cardBg};
  font-family: 'Poppins';
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
`

export const NotableImage = styled.div``

export const TextDiv = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 4px;
`
export const TextInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

const poppins = css`
  font-family: 'Poppins';
`

export const CollectionName = styled.div`
  ${poppins};
  font-weight: 700;
  font-size: 23px;
  line-height: 30px;
  color: ${({ theme }) => theme?.phaseTwoColor?.text};
`

export const UserName = styled.div`
  ${poppins};
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: ${({ theme }) => theme?.phaseTwoColor?.text};
`

export const TextValues = styled.div`
  display: flex;
  flex-direction: column;
`
export const FloorHeading = styled.div`
  ${poppins};
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: ${({ theme }) => theme?.phaseTwoColor?.paragraphText}; 
`
export const Values = styled.div`
  ${poppins};
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: ${({ theme }) => theme?.phaseTwoColor?.paragraphText};
`
export const ViewAllBtn = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 32px;
`
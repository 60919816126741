import { Dispatch, memo, SetStateAction } from 'react'
import { Tab, Tabs } from './TabFilter.style'

interface I_FilterProps {
  data: {
    value?: string
    label?: string
  }[],
  item?: string
  setItem?: any
}

const Filter = (props: I_FilterProps) => {

  const { data, item, setItem } = props
  return (
    <Tabs>
      {
        data?.map((info, i) => (
          <Tab 
            key={info?.value}
            active={ item === info?.value }
            onClick={() => { setItem(info?.value) }}
          >
            { info.label }
          </Tab>
        ))
      }
    </Tabs>
  )

}

const TabFilter = memo(Filter)

export default TabFilter
import styled from 'styled-components'
import { media } from 'utilities/helpers'
export const CreateAndSellSectionLayout = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 30px;
`
export const CreateAndSellCardText = styled.div`
  font-weight: 400;
  font-size: 16px;
  color: ${({ theme }) => theme.phaseTwoColor.paragraphText};
  margin-bottom: 25px;
`
export const CreateAndSellCardSubText = styled(CreateAndSellCardText)``
export const CreateAndSellCardTextLink = styled.a`
  text-decoration: none;
  color: ${({ theme }) => theme.phaseTwoColor.brandColor};
`
export const CreateAndSellSectionHeader = styled.div`
  font-family: 'Stray900';
  font-size: 50px;
  color: ${({ theme }) => theme.phaseTwoColor.text};
  text-align: center;
  margin: 50px 0px;
  ${media.sm`
  margin: 30px 0px;
  font-size: 30px;
  `}
`

import styled, { keyframes, css } from 'styled-components'
import { themeDark } from 'styles/theme'

export const TrendingLayout = styled.div<any>`
  width: 100%;
  overflow: hidden;
`
export const TrendingHeader = styled.div<any>`
`
export const FilterDiv = styled.div`
  overflow-x: auto;
  ::-webkit-scrollbar {
    height: 5px;
  }
  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
  }
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${themeDark.brandColor};
    border-radius: 10px;
  }
`
export const TrendingContainer = styled.div`
  margin: 30px 0px;
`
export const Slider1Container = styled.div`
  margin-bottom: 12px;
`
export const Slider2Container = styled.div``

export const LoaderDiv = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
`
export const Wrapper = styled.div`
  width: 100%;
  height: fit-content;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const Marquee = styled.div`
  display: flex;
  /* width: 1200px; */
  width: 100%;
  overflow: hidden;
  user-select: none;

  /* mask-image: linear-gradient(
    to right,
    hsl(0 0% 0% / 0),
    hsl(0 0% 0% / 1) 10%,
    hsl(0 0% 0% / 1) 90%,
    hsl(0 0% 0% / 0)
  ); */
`;

export const scrollX = keyframes`
  from {
    left: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
`;

export const common = css`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: space-around;
  white-space: nowrap;
  gap: 20px;
  /* width: 100%; */
  /* height: 100%; */
  animation: ${scrollX} 30s linear infinite;
  padding-left: 20px;
`;

export const MarqueeGroup = styled.div<any>`
  ${common}
  margin-bottom: 10px;
  ${props =>
    !props?.scroll &&
    css`
      /* animation: null; */
      -webkit-animation-play-state:paused;
      -moz-animation-play-state:paused;
      -o-animation-play-state:paused;
      animation-play-state:paused;
    `};
`;

export const MarqueeGroup2 = styled.div<any>`
  ${common}
  animation-direction: reverse;
  animation-delay: -1s;
  ${props =>
    !props?.scroll &&
    css`
      /* animation: null;
      animation-direction: none;
      animation-delay: none; */
      -webkit-animation-play-state:paused;
      -moz-animation-play-state:paused;
      -o-animation-play-state:paused;
      animation-play-state:paused;
    `};
`;

export const UserInfo = styled.div`
  display: flex;
  gap: 10px;
  position: absolute;
  bottom: 30px;
  left: 24px;
  display: none;
`
export const InfoText = styled.div``

export const CollectionName = styled.div`
  font-weight: 700;
  font-size: 23px;
  line-height: 30px;
  color: ${({ theme }) => theme?.phaseTwoColor?.bodyColor};
`
export const UserName = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: ${({ theme }) => theme?.phaseTwoColor?.bodyColor};
`
export const NoDataDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`
export const MarketPlaceBtn = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px;
`
export const CollectionDiv = styled.div`
  border-radius: 21px;
  cursor: pointer;
  width: 100%;
  height: 100%;
  position: relative;
  &:hover {
    opacity: 0.9;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.15), 0px 4px 7px rgba(0, 0, 0, 0.05), 0px 12px 40px rgba(0, 0, 0, 0.1);
  }
  &:hover ${UserInfo} {
    display: block;
  }
`
import React, { useEffect, useState } from 'react'
import { ArrowIcon } from '../../../styles/defaultStyles'
import { ImArrowRight2 } from 'react-icons/im'
import { useAppSelector } from 'hooks/useAppSelector'
import { themeDark } from 'styles/theme'

const SliderNextArrow = ({ onClick, topPosition }: any) => {
  const [disable, setDisable] = useState(false)
  const { theme } = useAppSelector(state => state.theme)

  useEffect(() => {
    if (onClick === null) {
      setDisable(true)
    } else {
      setDisable(false)
    }
  }, [onClick])

  return (
    <ArrowIcon topPosition={topPosition} themeMode={theme} isDisable={disable} onClick={onClick}>
      <ImArrowRight2
        style={{
          color:
            theme === 'light'
              ? disable
                ? themeDark.assetDetail.cardTitle
                : themeDark.brandColor
              : disable
              ? themeDark.buttonColor
              : themeDark.white,
        }}
      />
    </ArrowIcon>
  )
}

export default SliderNextArrow

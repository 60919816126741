import HomePage from 'modules/homePage'
import NotificationBar from 'modules/homePage/components/NotificationBar'
import type { NextPage } from 'next'
import { OpacityAnimation } from 'styles/sharedStyles'
import HeaderMetaInfo from '../shared/headerMetaInfo/HeaderMetaInfo'
import { Container } from '../styles/defaultStyles'

const Home: NextPage = () => {
  return (
    <>
      <HeaderMetaInfo pageTitle="Jungle" />
      {/* <Container> */}
      <NotificationBar />
        <OpacityAnimation>
          <HomePage />
        </OpacityAnimation>
      {/* </Container> */}
    </>
  )
}

export default Home

import styled from "styled-components";

export const TopSectionWrapper = styled.div<any>`
    background-color: ${({themeMode})=> themeMode === 'light' ? '#F6F6F6' : 'grey'};
    padding: 10px;
    /* height: 334px; */
    border-radius: 15px;
    cursor: pointer;
`;

export const ImageWrapper = styled.div`
    margin-top: -75px;
    position: relative;
    /* background-color: beige; */
    /* width: 400px; */
    height: 289px;
    border-radius: 15px;
    z-index: 0;
`;

export const AvatarImage = styled.div`
    position: relative;
    z-index:10;
    margin-left: 16px;
    height: 100px;
    width: 100px;
    border: 2px solid #FFFFFF;
    margin-top: -70px;
    border-radius: 100px;
`;

export const HeaderText = styled.div`
    margin: 16px 0 0 16px;
    font-family: 'Poppins';
    font-weight: 700;
    font-style: normal;
    font-size: 23px;
    line-height: 30px;
`;

export const BottomText = styled.div`
    margin-left: 16px;
    font-family: 'Poppins';
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
`;